<template>
    <div>
        <input hidden type="text" class="form-control" id="MutasiSaldoId" v-model="MutasiSaldoId">
        <div class="modal fade" id="MutasiSaldoStatusModal" tabindex="-1" aria-labelledby="MutasiSaldoStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MutasiSaldoStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{MutasiSaldoNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import mutasiSaldoService from '../Script/MutasiSaldoServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'MutasiSaldoStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            MutasiSaldoId : '',
            MutasiSaldoNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(mutasiSaldoData){
            this.ModalTitle = 'Edit Status Mutasi Saldo: ';
            this.MutasiSaldoId = mutasiSaldoData.debit_mutation_id;
            this.MutasiSaldoNo = '';
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_saldo_mutation');
            this.Status = mutasiSaldoData.status;
            
            window.$('#MutasiSaldoStatusModal').modal('show');
        },
        async saveClick(){
            var data = await mutasiSaldoService.getMutationSaldoQueryById(this.MutasiSaldoId);

            const mutasiSaldoData = {
                debit_mutation_date: data.debit_mutation_date,
                status: this.Status,
                contact_id: data.contact_id,
                debit: parseFloat(data.debit),
                notes: data.notes,
            };

            const variables = {
                id : this.MutasiSaldoId,
                data : mutasiSaldoData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    mutasiSaldoService.editQuery(variables).then(res => {
                        this.reload();
                        window.$('#MutasiSaldoStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>